import React, { createContext, useContext, useState } from "react"

type ContextStore = [any, (value: any) => void]

type StoreContext = {
  activeSection: ContextStore
  navbarHeight: ContextStore
}

const storeContextDefault: StoreContext = {
  activeSection: ["home", () => {}],
  navbarHeight: [0, () => {}],
}

export const StoreContext = createContext(storeContextDefault)

export const StoreProvider = ({ children }: {children: React.ReactNode | React.ReactNode[]}) => {

  const store = {
    activeSection: useState("home"),
    navbarHeight: useState(0),
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export const useNavbarHeightStore = () => {
  const context = useContext(StoreContext)
  return context && context.navbarHeight
}

export const useNavbarHeight = () => {
  return useNavbarHeightStore()[0]
}

export const useActiveSectionStore = () => {
  const context = useContext(StoreContext)
  return context && context.activeSection
}

export const useActiveSection = () => {
  return useActiveSectionStore()[0]
}
